<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-05-16 18:23:26
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-06-03 10:17:09
 * @FilePath: /mediatom-web/src/viewsForManage/exception/404.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-result title="未找到页面" sub-title="对不起，未找到该页面">
    <template #icon>
      <!-- <NotFoundSvg style="width: 200px; height: 300px" /> -->
      <img src="/images/404.png" alt="">
    </template>
    <template #extra>
      <a-button type="primary" @click="toHome"> 返回{{ firstRouteName }} </a-button>
    </template>
  </a-result>
</template>

<script>
import { mapState } from 'vuex'
// import NotFoundSvg from '@/assets/icons/404.svg?inline'
export default {
  // components: { NotFoundSvg },
  name: 'Exception404',
  data () {
    return {
      firstRouteName: '',
      firstRoute: ''
    }
  },
  computed: {
    ...mapState({
      routers: (state) => state.permission.routers
    })
  },
  mounted () {
    this.firstRoute = this.routers.find((item) => item.name === 'index').children[0]
    this.firstRouteName = this.firstRoute.meta.title
  },
  methods: {
    toHome () {
      this.$router.push({ path: this.firstRoute.path })
    }
  }
}
</script>

<style lang="less" scoped>
img{
  min-height: 360px;
}
button{
  margin-top: 70px;
  padding: 0 30px;
}
</style>
